body {
  font-family: "Source Sans Pro", sans-serif;
}
* {
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
  filter: none;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=‘0’);
}
.container {
  // max-width: 768px;
  width: 100%;
  margin: 0 auto;
}

.home {
  max-width: 768px;
  width: 100%;
  height: calc(100vh - 76px);
}

h1,
h3,
p,
div {
  font-family: "Source Sans Pro", sans-serif;
}

// height: calc(100vh - 2px);

// Welcome Screen
.welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  img {
    max-width: 250px;
  }
}

// Onboarding Screen
.onbording {
  height: calc(100% - 60px);
  .header {
    img {
      width: 100%;
    }
  }

  .HeadingContent {
    padding: 0px 20px;
    margin-top: -20px;
    h1 {
      font-size: 24px;
      font-weight: 600;
    }
    p {
      font-size: 16px;
      color: #7b7d82;
      margin-top: 8px;
    }
  }
  .btn {
    background-color: #f3f5ff;
    max-width: 170px;
    width: 170px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    padding-left: 20px;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    color: #2c4bff;
    position: relative;
    margin-top: 15px;
    span {
      background-color: #2c4bff;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }
  }
}

//main
.main {
  .header {
    background-color: #fef0dd;
    height: 270px;
    width: 100%;
    // background-image: url('../images/main-header-bg.png');
    background-position: left bottom;
    background-size: auto 120px;
    background-repeat: repeat-x;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: fixed;

    .bannerImg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    .layer {
      position: absolute;
      background: #f1f3f4;
      opacity: 0.5;
      content: "";
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .HeadingContent {
      padding: 35px 20px 0px 20px;
      position: relative;
      z-index: 10;
      h1 {
        font-size: 24px;
        font-weight: 600;
        color: #151515;
      }
      p {
        text-align: justify;
        font-size: 16px;
        color: #fff;
        margin-top: 8px;
      }
    }

    .btn-2 {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 20px;
      max-width: 230px;
      position: absolute;
      bottom: 56px;
      color: #2c4bff;
      text-decoration: none;
      font-weight: 600;
      z-index: 10;
      border-radius: 35px;
      &:focus {
        outline-offset: unset !important;
      }
    }
  }

  .title {
    display: flex;
    justify-content: center;
    position: relative;

    &:before {
      content: "";
      width: 90px;
      height: 4px;
      border-radius: 10px;
      background-color: #c4c4c4;
      position: absolute;
      top: 0px;
      left: calc(50% - 50px);
    }

    .titleContent {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 20px;

      .brandLogo-Wrap {
        width: 72px;
        height: 72px;
        border: 2px solid #2c4bff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          width: 68px;
          height: 68px;
          object-fit: contain;
          padding: 20px 8px;
          border-radius: 100%;
        }
      }

      h3 {
        font-size: 16px;
        font-weight: 600;
        margin-left: 14px;
        span {
          display: block;
          font-size: 14px;
          color: #7b7d82;
          font-weight: 400;
        }
      }

      .rating {
        margin-left: auto;
        display: flex;
        align-items: center;
        color: #7b7d82;
        img {
          max-width: 20px;
          margin-right: 5px;
        }
      }
    }
  }

  .paymentOption {
    background-color: #2c4bff;
    display: flex;
    align-items: center;
    margin-top: 20px;
    border-radius: 10px;
    padding: 15px 20px;
    position: relative;
    h3 {
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      span {
        display: flex;
        font-size: 14px;
        color: #f3f5ff;
        font-weight: 400;
      }
    }

    .paymentC {
      list-style-type: none;
      margin: 0px;
      padding: 0px;
      width: 90px;
      position: absolute;
      right: 40px;
      top: 10px;
     
      li {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #fff;
        float: left;
        margin-left: -6px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2c4bff;
        font-size: 12px;
        font-weight: 600;
        border: 1px solid #fff;
      }
    }

    .arrowBtn {
      width: 32px;
      height: 32px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-left: auto;
    }
  }

  .menusection {
    margin-top: 20px;

    .heading {
      h3 {
        font-size: 20px;
        font-weight: 600;
        color: #151515;
        span {
          display: block;
          font-size: 14px;
          color: #7b7d82;
          font-weight: 400;
        }
      }
    }

    .sectionfood {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      .foodBox {
        flex-basis: 60px;
        margin-right: 5%;

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 64px;
          // border: 1px solid #e1e1e2;
          height: 64px;
          justify-content: center;
          border-radius: 100%;
          // padding: 5px;
          .food {
            background-color: #ffd8db;
            color: #cb202d;
          }
          .drinks {
            background-color: #fff4d7;
            color: #d99d00;
          }

          .Food {
            background-color: #ffd8db;
            color: #cb202d;
          }
          .Drink {
            background-color: #fff4d7;
            color: #d99d00;
          }
          img {
            border-radius: 50%;
            width: 64px;
          }
        }
        .foodBoxInner {
          .foodLink {
            // padding: 2px 0px 0 0;
            // font-size: 12px;
            // text-decoration: none;
            // margin-top: 8px;
            // border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px 10px;
            font-size: 16px;
            text-decoration: none;
            margin-top: 8px;
            border-radius: 30px;
            white-space: nowrap;
          }
          ul {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            li {
              margin-right: 5%;
              margin-bottom: 20px;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              flex-direction: column;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .borderWrapper {
    .section {
      border-bottom: 1px solid #eaeaea;
      &:last-child {
        border-bottom: none;
      }
    }
  }

  // Rate us
  .RateUs {
    margin-top: 20px;
    .heading {
      margin-bottom: 20px;
      h3 {
        font-size: 20px;
        font-weight: 600;
        color: #151515;
        span {
          display: block;
          font-size: 14px;
          color: #7b7d82;
          font-weight: 400;
        }
      }
    }

    .Rcontainer {
      list-style-type: none;
      margin: 0px;
      padding: 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      &.b-none {
        border-bottom: unset;
      }
      li {

        margin-right: 5%;
        margin-bottom: 20px;
        a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            max-width: 64px;
            width: 64px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .foodLink {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 10px;
        font-size: 16px;
        text-decoration: none;
        margin-top: 8px;
        border-radius: 30px;
      }
    }
  }

  .feedback {
    margin-top: 20px;
    padding-bottom: 20px;
    .heading {
      margin-bottom: 20px;
      h3 {
        font-size: 20px;
        font-weight: 600;
        color: #151515;
        span {
          display: block;
          font-size: 14px;
          color: #7b7d82;
          font-weight: 400;
        }
      }
    }

    .btncont {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      .btn {
        background-color: #f3f5ff;
        max-width: max-content;
        width: max-content;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        gap: 4px;
        font-weight: 600;
        color: #2c4bff;
        padding: 8px 12px;
        span {
          img {
            max-width: 24px;
            margin-right: 5px;
          }
        }
        div{
          text-overflow: ellipsis !important;
          overflow: hidden !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
        }
      }
    }
  }

  .scrachCard {
    background-color: #f3f5ff;
    padding: 20px 20px;
    display: flex;
    height: 400px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    position: relative;

    .canvas {
      position: absolute;
      top: 0;
    }

    .heading {
      margin-top: 20px;
      margin-bottom: 20px;
      h3 {
        font-size: 20px;
        font-weight: 600;
        color: #151515;
        text-align: center;
        span {
          display: block;
          font-size: 14px;
          color: #7b7d82;
          font-weight: 400;
        }
      }
    }
  }
}

.contentArea {
  padding: 20px;
  width: calc(100% - 40px) !important;
}
.maincontent {
  margin-top: 226px;
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  position: absolute;
}

.pb-0 {
  padding-bottom: 0px;
}

.ptb-15 {
  padding-top: 15px !important;
  padding-bottom: 15px 15 !important;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 0 24px 0;
  div {
    display: flex;
    align-items: center;
    p {
      padding-right: 8px;
      font-size: 14px;
      color: #7b7d82;
    }
    img {
      width: 67px;
      height: auto;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Popup modal

#container {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
}

div#popUpForm {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  top: 0;
  position: fixed;
  z-index: 999999;
  right: 0;
}

#popContainer {
  max-width: 100%;
  text-align: center;
  background-color: #fff;
  min-height: 400px;
  margin-top: 80px;
  border-radius: 10px;
  margin: 80px 20px 0px 20px;
  display: flex;
  z-index: 99999;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;

  .rannkly {
    display: flex;
    align-items: center;
    margin-top: 40px;
    p {
      padding-right: 8px;
      font-size: 14px;
      color: #7b7d82;
    }
    img {
      width: 67px;
      height: auto;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .heading {
    margin-top: 0px;
    margin-bottom: 34px;
    h3 {
      font-size: 20px;
      font-weight: 600;
      color: #151515;
      text-align: center;
      span {
        display: block;
        font-size: 14px;
        color: #7b7d82;
        font-weight: 400;
      }
    }
  }

  form {
    width: 90%;
  }
  .textfield {
    border-radius: 50px;
    height: 48px;
    width: 100%;
    border: 1px solid #e1e1e2;
    margin-bottom: 12px;

    svg {
      max-width: 16px;
      margin-left: 15px;
      margin-right: 5px;
    }
    input {
      height: 40px;
      margin-top: 5px;
      width: calc(100% - 68px);
      border: 0px;
      padding-left: 10px;
      font-family: "Source Sans Pro", sans-serif;
    }

    &:hover {
      border: 1px solid #2c4bff;

      svg {
        fill: #2c4bff !important;
        path {
          fill: #2c4bff !important;
        }
      }
    }
    .textfieldInner {
      display: flex;
      align-items: center;
    }
    &:nth-child(3) {
      margin-bottom: 0px;
    }
  }
  .errorMsg {
    display: flex;
    justify-content: flex-start;
    padding: 0px 0 12px 30px;
    margin-top: -12px;
    width: 100%;
    label {
      color: #cb202d;
    }
  }

  .tableorderTextField {
    height: 100%;
    padding: 0 14px;
  }

  .selectListOrder {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    color: #7b7d82;
    font-size: 16px;
    background-color: #fff !important;
    &:focus-visible {
      outline: none;
    }
  }
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#close {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 10px 15px;
  font-size: 14px;
  font-weight: 700 !important;
  cursor: pointer;
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  color: #fff;
}

.submit {
  background-color: #2c4bff;
  width: 175px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  outline: none;
  border: none;

  margin-top: 20px;
  border-radius: 50px !important;
  font-family: "Source Sans Pro", sans-serif;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.Hfooter {
  padding: 0px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

input:focus {
  outline: none;
  border: none;
}

.scrachCard {
  canvas {
    z-index: 9999;
  }
}

.scrachCardContent {
  position: absolute;
  font-weight: 700;
  font-size: 20px;
}
.scrachCardCont {
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.martop {
  margin-top: -65px;
}
.choosePaymentSheet [data-rsbs-overlay]{
  background: #e2e6e9;
  touch-action: inherit;
}

.headingPrice {
  margin-top: 20px;
  margin-bottom: 20px;
  h3 {
    font-size: 20px;
    color: #151515;
    // text-align: center;
    font-weight: 600;
    padding: 0 20px;
  }
  
  p{
    font-size: 16px;
    margin: 10px 0;
    font-weight: 700;
    padding: 0 20px;
    color: #151515;

  }
  

  .paymentC {
    list-style-type: none;
    margin: 0px;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    li {
      width: 100%;
    height: 65px;
    border-radius: 8px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #2c4bff;
    font-size: 12px;
    font-weight: 600;
    margin-right: 15px;
    border: none;
    outline: none;
    background: white;
    margin-bottom: 10px;
      a {
        border: none;
        outline: none;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        span{
          font-size: 16px;
          color: #151515;
          font-weight: 600;
          
        }
      }
      img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        padding: 8px;
        // border: 1px solid #e1e1e2;
      }
    }
  }
}

.google-text {
  background-color: #e0eeff;
  color: #1878f3;
}
.zomato-text {
  background-color: #ffd8db;
  color: #cb202d;
}
.swiggy-text {
  background-color: #ffe4ce;
  color: #fc8019;
}
.link-text {
  background-color: #f6f6f6;
  color: #7b7d82;
}
// .whatsapp-text {
//   background-color: #d9ffe0;
//   color: #1bd741;
// }
// .telegram-text {
//   background-color: #e1f7ff;
//   color: #40b3e0;
// }
// .sms-text {
//   background-color: #bff6f1;
//   color: #009688;
// }
// .twitter-text {
//   background-color: #daf0ff;
//   color: #1d9bf0;
// }
// .instagram-text {
//   background-color: #ffd8db;
//   color: #cb202d;
// }

// .linkedin-text {
//   background-color: #dbedff;
//   color: #0a66c2;
// }

.services-text {
  background-color: #dbedff;
  color: #0a66c2;
  max-width: 60px;
  line-height: 20px;
}

.facebook-bg {
  background-color: #1878f3;
  border-radius: 100%;
  max-width: 48px;
  height: 48px;
}

.linkedin-bg {
  background-color: #0a66c2;
  border-radius: 100%;
  max-width: 48px;
  height: 48px;
}

.twitter-bg {
  background-color: #1d9bf0;
  border-radius: 100%;
  max-width: 48px;
  height: 48px;
}

.telegram-bg {
  background-color: #40b3e0;
  border-radius: 100%;
  max-width: 48px;
  height: 48px;
}

.whatsapp-bg {
  background-color: #1bd741;
  border-radius: 100%;
  max-width: 48px;
  height: 48px;
}

.google-text,
.zomato-text,
.swiggy-text,
.services-text,
.facebook-text,
.whatsapp-text,
.telegram-text,
.sms-text,
.twitter-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  font-size: 12px;
  text-decoration: none;
  margin-top: 8px;
  border-radius: 30px;
}

.textfield {
  &:focus,
  &:hover {
    img.svg {
      fill: #f00 !important;
    }
  }
}
.mainSec {
  background-color: #f6f6f6;
}

.backTop {
  padding: 10px 20px;
  .backBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2c4bff;
    img {
      margin-right: 6px;
    }
  }
}

.offerSecMain {
  background-color: #fff;
  padding: 20px 20px 0;
  border-radius: 12px;
  .specialOfferSec {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .getOfferSec {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    .offerImgSec {
      margin-bottom: 4px;
      img {
        max-width: 24px;
      }
    }
    h4 {
      font-family: "Source Sans Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: #151515;
      margin-bottom: 4px;
    }
    p {
      font-family: "Source Sans Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #7b7d82;
    }
  }

  .applyCouponSec {
    display: flex;
    align-items: center;
    padding: 20px 16px;
    background: #e6f9f7;
    border: 1px solid #00c5ac;
    border-radius: 10px;
    margin-bottom: 20px;
    .ApplimgSec {
      margin-right: 12px;
      img {
        max-width: 24px;
      }
    }
    P {
      font-family: "Source Sans Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #00c5ac;
    }
  }

  .CardSecMain {
    // margin-bottom: 100px;
    .card {
      margin-bottom: 20px;
      padding: 16px 10px 8px;
      background: #2c4bff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      position: relative;
      .detailOuter {
        display: flex;
        align-items: center;
        padding-bottom: 12px;
        border-bottom: 1px solid #697bff;
        margin-bottom: 8px;
        margin-left: 8px;
        .menuImgSec {
          width: 135px;
          margin-right: 12px;
          img {
            width: 100px !important;
            height: 100px;
            object-fit: cover;
            border-radius: 100%;
          }
        }
        .maxwidth {
          max-width: 100px !important;
          img {
            height: auto !important;
          }
        }
        .menudetailSec {
          h5 {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #ffffff;
          }
          p {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #ffffff;
            margin-bottom: 12px;
            span {
              &:last-child {
                text-decoration: line-through;
                margin-left: 5px;
              }
            }
          }
          .detailPara {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            display: flex;
            align-items: center;
            padding: 7px 15px;
            background: #f5f9e6;
            border-radius: 30px;
            color: #7d9a04;
            margin-bottom: 0px;
          }
        }
      }

      .validSec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .validPara {
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          padding: 4px 10px;
          background: #f5f9e6;
          border-radius: 30px;
          color: #7d9a04;
        }
        .TermPara {
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #ffffff;
        }
        .expirePara {
          font-family: "Source Sans Pro";
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #df3e4d;
          background: #fceced;
          border-radius: 30px;
          padding: 4px 10px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .varietyLogo {
    position: absolute;
    top: 8px;
    left: 8px;
    img {
      max-width: 20px;
    }
  }
}

.npad {
  padding: 0 !important;
  border-radius: 0px !important;
}

.dflex {
  display: flex;
  justify-content: space-between !important;
  flex-direction: row !important;
  width: 100%;
  margin-top: 20px;

  a {
    font-size: 14px;
    color: #2c4bff;
    font-weight: 600;
    display: flex;
    align-items: center;
    img {
      margin-left: 6px;
      width: 6px;
      height: 10px;
    }
  }
}
.headContof {
  display: flex;
  align-items: center;
  .offerImgSec {
    margin-right: 6px;
  }
}
.homeofCont {
  display: flex;
  flex-direction: column;
}

.newPro {
  position: fixed;
  left: 0px;
  top: 0;
  height: 100vh;
  z-index: 9999;
  width: 100%;
  background-color: #fff;
  overflow: auto;
}

.terms {
  margin-top: 15px;
  margin-left: 30px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  li {
    line-height: 22px;
    &::marker {
      font-weight: 700;
    }
  }
}
